<p>
  Z radością informujemy, że zakończyliśmy prace serwisowe i przenieśliśmy naszą
  stronę na nowy, szybszy i bardziej wydajny serwer!
</p>
<p>
  Dzięki temu korzystanie z serwisu będzie jeszcze sprawniejsze, a czas
  ładowania znacznie się skróci.
</p>
<p>
  <strong
    >Ze względu na zmiany techniczne, prosimy wszystkich użytkowników o
    założenie nowego konta.</strong
  >
</p>
<p>
  W trosce o bezpieczeństwo i wygodę korzystania z serwisu, dotychczasowe konta
  nie zostały przeniesione.
</p>

<h2>Co należy zrobić?</h2>
<ol class="pl-4 pb-4">
  <li>Przejdź do strony rejestracji.</li>
  <li>Utwórz nowe konto, podając swoje dane.</li>
  <li>Ciesz się lepszą i szybszą wersją naszego serwisu!</li>
</ol>

<a href="/rejestracja" class="button">Załóż nowe konto</a>

<p>Dziękujemy za wyrozumiałość i cieszymy się, że jesteście z nami!</p>
<p><strong>Zespół Swiss-car.pl</strong></p>
