<div #myDiv>
  <p-card class="section section--full">
    <div>
      <button
        pButton
        class="surface-0 h-4rem text-base border-white border-round-md active-link mb-2"
        (click)="onAddNewRow()"
      >
        Dodaj wiersz
      </button>
    </div>
    <p-table
      [value]="access"
      [scrollable]="true"
      [rowHover]="true"
      [autoLayout]="true"
      [lazy]="true"
      [resizableColumns]="true"
      [totalRecords]="totalRecords"
      [loading]="loadingTable"
      [sortField]="'key'"
      dataKey="access_id"
      editMode="row"
    >
      <ng-template pTemplate="caption">
        <div class="table-header flex justify-content-between">
          Lista wszystkich kluczy dostępu
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 33%" [pSortableColumn]="'key'">
            Klucz
            <p-sortIcon [field]="'key'"></p-sortIcon>
          </th>
          <th style="width: 33%">Wartość</th>
          <th style="width: 33%"></th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-access
        let-editing="editing"
        let-ri="rowIndex"
      >
        <tr [pEditableRow]="access">
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="access.key" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ access?.key }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="access.password" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ access?.password }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
              <button
                *ngIf="!editing"
                pButton
                pRipple
                type="button"
                pInitEditableRow
                icon="pi pi-pencil"
                (click)="onRowEditInit(access)"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                *ngIf="!editing"
                pButton
                pRipple
                type="button"
                icon="pi pi-trash"
                (click)="onRowDelete(access, ri)"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                pSaveEditableRow
                icon="pi pi-check"
                (click)="onRowEditSave(access)"
                class="p-button-rounded p-button-text p-button-success mr-2"
              ></button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                pCancelEditableRow
                icon="pi pi-times"
                (click)="onRowEditCancel(access, ri)"
                class="p-button-rounded p-button-text p-button-danger"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template [pTemplate]="'emptymessage'">
        <tr [class]="'empty-data'">
          <td colspan="10" class="h-30rem flex justify-content-center">
            Brak wyników do wyświetlenia
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator
      [totalRecords]="totalRecords"
      [rowsPerPageOptions]="rowPerPage"
      [rows]="visibleRows"
      [showCurrentPageReport]="true"
      [currentPageReportTemplate]="
        'Showing {first} to {last} of {totalRecords} entries'
      "
    ></p-paginator>
  </p-card>
</div>
<p-confirmDialog
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>

<p-toast></p-toast>
