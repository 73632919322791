import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ComAPIService } from '../API/com-api.service';
import { StorageManipulationService } from '../API/storage-manipulation.service';
import { Subscription } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NewAccountDialogComponent } from './new-account-dialog/new-account-dialog.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DialogService],
})
export class HeaderComponent implements OnInit, OnDestroy {
  showmenu = false;
  isLogin = false;
  href: string = '';
  router$: Subscription;
  isLogin$: Subscription;
  dynamicDialogRef: DynamicDialogRef;

  constructor(
    private router: Router,
    private eRef: ElementRef,
    public service: ComAPIService,
    private local: StorageManipulationService,
    public dialogService: DialogService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isLogin$ = local.itemValue.subscribe((nextValue) => {
      this.isLogin = !!nextValue; // this will happen on every change
    });
  }

  ngOnInit() {
    this.router$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.href = event.url;
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      this.showNewAccountDialog();
    }
  }

  ngOnDestroy() {
    this.isLogin$.unsubscribe();
    this.router$.unsubscribe();
    if (this.dynamicDialogRef) {
      this.dynamicDialogRef.close();
    }
  }

  scrollButton() {
    document
      .getElementById('sample-auctions')!
      .scrollIntoView({ behavior: 'smooth' });
  }

  clickout(event: any) {
    if (this.eRef.nativeElement.contains(event.target)) {
      console.log('click inside');
    } else {
      console.log('click outside');
    }
  }
  showMenu() {
    this.showmenu = !this.showmenu;
  }

  private showNewAccountDialog() {
    if (!localStorage.getItem('newAccountDialog')) {
      localStorage.setItem('newAccountDialog', 'true');
      this.dynamicDialogRef = this.dialogService.open(
        NewAccountDialogComponent,
        {
          header: 'Ważna informacja dla użytkowników!',
        }
      );
    }
  }
}
