<section
  class="mt-7 mb-7 flex justify-content-start align-items-center flex-column"
>
  <h1 class="login-container-title">
    {{ isMineSettings ? "Moje ustawienia" : "Zarejestruj się" }}
  </h1>
  <p>
    {{
      isMineSettings
        ? ""
        : "Jeśli jeszcze nie posiadasz konta na naszym serwisie"
    }}
  </p>
  <div
    class="grid col-12 md:col-10 xl:col-7 surface-100 flex justify-content-center align-items-center p-5 border-round-md"
  >
    <form
      class="col-12 flex justify-content-center align-items-center flex-column"
      [formGroup]="registerForm"
    >
      <div class="col-10 xl:col-7 flex flex-column align-items-start p-2">
        <label class="mr-2" for="login">Login:</label>
        <input
          [ngClass]="{
            'ng-invalid ng-dirty': f['login'].dirty || f['login'].touched
          }"
          required
          class="w-full"
          id="login"
          [formControlName]="'login'"
          type="login"
          pInputText
          [disabled]="isMineSettings"
          [autocomplete]="!isMineSettings"
          placeholder="Służy do logowania"
        />
        <ng-container
          *ngIf="f['login']?.errors && (f['login'].dirty || f['login'].touched)"
        >
          <small *ngIf="f['login']?.errors['required']" class="p-error block"
            >Login jest wymagany</small
          >
        </ng-container>
      </div>
      <div class="col-10 xl:col-7 flex flex-column align-items-start p-2">
        <label class="mb-1" for="name">Imię</label>
        <input
          [ngClass]="{
            'ng-invalid ng-dirty': f['name'].dirty || f['name'].touched
          }"
          required
          class="w-full"
          id="name"
          type="name"
          pInputText
          [formControlName]="'name'"
          [disabled]="isMineSettings"
          name="name"
          autocomplete="{{ !isMineSettings }}"
          placeholder="Podaj swoje imię"
        />
        <ng-container
          *ngIf="f['name']?.errors && (f['name'].dirty || f['name'].touched)"
        >
          <small *ngIf="f['name']?.errors['required']" class="p-error block"
            >Imię jest wymagane</small
          >
        </ng-container>
      </div>

      <div class="col-10 xl:col-7 flex flex-column align-items-start p-2">
        <label class="mb-1" for="surname">Nazwisko</label>
        <input
          [ngClass]="{
            'ng-invalid ng-dirty': f['surname'].dirty || f['surname'].touched
          }"
          required
          class="w-full"
          id="surname"
          pInputText
          [formControlName]="'surname'"
          [disabled]="isMineSettings"
          autocomplete="{{ !isMineSettings }}"
          type="nickname"
          name="surname"
          placeholder="Podaj swoje nazwisko"
        />
        <ng-container
          *ngIf="
            f['surname']?.errors && (f['surname'].dirty || f['surname'].touched)
          "
        >
          <small *ngIf="f['surname']?.errors['required']" class="p-error block"
            >Nazwisko jest wymagane</small
          >
        </ng-container>
      </div>

      <div class="col-10 xl:col-7 flex flex-column align-items-start p-2">
        <label class="mb-1" for="email">E-mail</label>
        <input
          [ngClass]="{
            'ng-invalid ng-dirty': f['email'].dirty || f['email'].touched
          }"
          required
          class="w-full"
          id="email"
          pInputText
          [formControlName]="'email'"
          autocomplete="{{ !isMineSettings }}"
          [disabled]="isMineSettings"
          type="email"
          name="email"
          email="true"
          placeholder="Jan@firma.com"
        />
        <ng-container
          *ngIf="f['email']?.errors && (f['email'].dirty || f['email'].touched)"
        >
          <small *ngIf="f['email']?.errors['required']" class="p-error block"
            >Email jest wymagany</small
          >
        </ng-container>
      </div>

      <div class="col-10 xl:col-7 flex flex-column align-items-start p-2">
        <label class="mb-1" for="phone">Numer telefonu</label>
        <p-inputMask
          class="w-full"
          id="phone"
          [ngClass]="{
            'ng-invalid ng-dirty': f['phone'].dirty || f['phone'].touched
          }"
          [disabled]="isMineSettings"
          autocomplete="{{ isMineSettings ? 'true' : 'false' }}"
          [formControlName]="'phone'"
          type="tel"
          mask="(999) 999-999-999"
          placeholder="(048) 123-456-789"
          [autoClear]="false"
        ></p-inputMask>
        <ng-container
          *ngIf="f['phone']?.errors && (f['phone'].dirty || f['phone'].touched)"
        >
          <small *ngIf="f['phone']?.errors['required']" class="p-error block"
            >Numer telefonu jest wymagany</small
          >
        </ng-container>
      </div>

      <div class="col-10 xl:col-7 flex flex-column align-items-start p-2">
        <label class="mb-1" for="password">Hasło</label>
        <input
          [ngClass]="{
            'ng-invalid ng-dirty': f['password'].dirty || f['password'].touched
          }"
          required
          autocomplete="off"
          pInputText
          class="w-full"
          type="password"
          id="password"
          placeholder="Musi mieć od 8 do 20 znaków"
          [formControlName]="'password'"
        />
        <ng-container
          *ngIf="
            f['password']?.errors &&
            (f['password'].dirty || f['password'].touched)
          "
        >
          <small *ngIf="f['password']?.errors['required']" class="p-error block"
            >Hasło jest wymagane</small
          >
          <small
            *ngIf="
              f['password']?.errors['matching'] &&
              f['password'].value.length > 7
            "
            class="p-error block"
            >Podane hasła nie są identyczne</small
          >
          <small
            *ngIf="f['password']?.errors['minlength']"
            class="p-error block"
            >Podane hasło musi mieć minimum 8 znaków</small
          >
          <small
            *ngIf="f['password']?.errors['maxlength']"
            class="p-error block"
            >Podane hasło może mieć maksymalnie 20 znaków</small
          >
        </ng-container>
      </div>

      <div class="filed col-10 xl:col-7 flex flex-column align-items-start p-2">
        <label class="mb-1" for="confirmPassword">Potwierdź hasło</label>
        <input
          [ngClass]="{
            'ng-invalid ng-dirty':
              f['confirmPassword'].dirty || f['confirmPassword'].touched
          }"
          required
          autocomplete="{{ !isMineSettings }}"
          pInputText
          class="w-full"
          type="password"
          id="confirmPassword"
          placeholder="Musi mieć od 8 do 20 znaków"
          [formControlName]="'confirmPassword'"
        />
        <ng-container
          *ngIf="
            f['confirmPassword']?.errors &&
            (f['confirmPassword'].dirty || f['confirmPassword'].touched)
          "
        >
          <small
            *ngIf="f['confirmPassword']?.errors['required']"
            class="p-error block"
            >Potwierdzenie hasła jest wymagane</small
          >
          <small
            *ngIf="f['confirmPassword']?.errors['minlength']"
            class="p-error block"
          >
            Podane hasło musi mieć minimum 8 znaków
          </small>
          <small
            *ngIf="f['confirmPassword']?.errors['maxlength']"
            class="p-error block"
          >
            Podane hasło może mieć maksymalnie 20 znaków
          </small>
          <small
            *ngIf="
              !f['confirmPassword']?.errors['matching'] &&
              f['confirmPassword'].value.length > 7
            "
            class="p-error block"
          >
            Podane hasła nie są identyczne
          </small>
        </ng-container>
      </div>
      <div class="col-10 xl:col-7">
        <p-button
          type="submit"
          styleClass="w-full p-button-danger form-button"
          label="{{ isMineSettings ? 'Zapisz' : 'Zarejestruj się' }}"
          [disabled]="registerForm.invalid"
          [loading]="loadingButton"
          (onClick)="onSubmit()"
        ></p-button>
      </div>
    </form>
  </div>
</section>
