import { isDevMode, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FooterComponent } from './footer/footer.component';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MySettingsRouters } from './my-account/account-routing.module';
import { ErrorHandlerInterceptor } from './API/error-handler.interceptor';
import { AddCockieInterceptor } from './API/add-cockie.interceptor';
import { PasswordModule } from 'primeng/password';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { UserActiveComponent } from './user-active/user-active.component';
import { HomePageModule } from './home-page/home-page.module';
import { RegisterPageModule } from './register-page/register-page.module';
import { LoginPageModule } from './login-page/login-page.module';
import { RememberPasswordModule } from './remember-password/remember-password.module';
import { AuctionsListModule } from './auctions-list/auctions-list.module';
import { HeaderComponent } from './header/header.component';
import { PageNotFoundModule } from './page-not-found/page-not-found.module';
import { DetailAuctionModule } from './detail-auction/detail-auction.module';
import { SampleAuctionsModule } from './sample-auctions/sample-auctions.module';
import { SharedModule } from './shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { devURL } from '../environments/environment';
import { NgOptimizedImage } from '@angular/common';
import { NewAccountDialogComponent } from './header/new-account-dialog/new-account-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NewAccountDialogComponent,
    FooterComponent,
    UserActiveComponent,
  ],
  imports: [
    // BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    DynamicDialogModule,
    MySettingsRouters,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      closeButton: true,
      progressBar: true,
      preventDuplicates: true,
      positionClass: 'toast-top-right',
    }),
    PasswordModule,
    SharedModule,
    HomePageModule,
    RegisterPageModule,
    LoginPageModule,
    RememberPasswordModule,
    AuctionsListModule,
    PageNotFoundModule,
    DetailAuctionModule,
    SampleAuctionsModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: devURL.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgOptimizedImage,
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddCockieInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
